import React, { useReducer, useCallback } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Flex from "../../components/flex"
import Typography from "../../components/typography"
import DoctorsAvatar from "../../components/doctorsAvatar"
import Place from "../../components/workplacesPlace"
import allDoctors from "../../api/doctors"
import { getClinicsByDoctor, getDoctorsWithClinic } from "../../api/clinics"
import Image from "../../assets/images/equipe_gastrocirurgica_jaleco.png"
import Select from "../../components/select"

const doctors = getDoctorsWithClinic(allDoctors)

const StyledImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 220px;
`

const initialState = {
  doctor: null,
  clinic: null,
  clinics: null,
}

const SET_DOCTOR = "SET_DOCTOR"
const SET_CLINIC = "SET_CLINIC"

const reducer = (state, { type, payload }) => {
  const actionTypes = {
    [SET_DOCTOR]: () => ({
      ...state,
      doctor: payload,
      clinic: null,
      clinics: getClinicsByDoctor(payload),
    }),
    [SET_CLINIC]: () => ({
      ...state,
      clinic: payload,
    }),
  }
  const action = actionTypes[type]
  return typeof action === "function" ? action() : state
}

const Schedule = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const setDoctor = useCallback(
    payload => dispatch({ type: SET_DOCTOR, payload }),
    [dispatch]
  )
  const setClinic = useCallback(
    payload => dispatch({ type: SET_CLINIC, payload }),
    [dispatch]
  )
  return (
    <Layout {...props}>
      <SEO
        title="Agendar consulta com um cirurgião da equipe GastroCirúrgica"
        description="Marque sua consulta em uma das clínicas em que a equipe GastroCirúrgica atende"
      />
      <Section padding="1rem 0 2rem 0">
        <Container>
          <Flex flexWrap="wrap-reverse" align="center" justify="space-between">
            <Flex child flex={100} flexGtSm={35} style={{ paddingTop: "2rem" }}>
              <Typography variant="h1" component="h1" color="secondary">
                Marcar consulta
              </Typography>
              <Typography variant="h5" component="h2" color="primary" pre>
                {`Escolha o médico e clínica para visualizar o contato para marcar consulta.`}
              </Typography>
              <Select
                label="Selecione um médico:"
                options={doctors}
                value={state.doctor}
                onChange={setDoctor}
                renderItem={value => (
                  <Flex align="center">
                    <Flex child size="initial" style={{ maxWidth: "70px" }}>
                      <DoctorsAvatar
                        enabled={{ [value.slug]: value }}
                        style={{ width: "60px" }}
                      />
                    </Flex>
                    <Flex child size="grown">
                      <Typography variant="bodyLarge" removeMargin>
                        {value.name}
                      </Typography>
                      <Typography variant="bodySmall">{`CRM ${value.crm}`}</Typography>
                    </Flex>
                  </Flex>
                )}
              />
              {state.clinics && (
                <Select
                  label="Selecione uma clínica:"
                  options={state.clinics}
                  value={state.clinic}
                  onChange={setClinic}
                  renderItem={value => (
                    <Flex column>
                      <Flex child>
                        <Typography variant="bodyLarge" removeMargin>
                          {value.name}
                        </Typography>
                      </Flex>
                      <Flex child>
                        <Typography variant="bodySmall">{`${value.city}`}</Typography>
                      </Flex>
                    </Flex>
                  )}
                />
              )}
            </Flex>
            <Flex child flex={100} flexGtSm={35}>
              <StyledImage
                src={Image}
                alt="Equipe GastroCirurgica"
              />
            </Flex>
          </Flex>
          {state.clinic && <Place hideDoctors {...state.clinic} />}
        </Container>
      </Section>
    </Layout>
  )
}

export default Schedule
